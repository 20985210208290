import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

import {
  Pump,
  Valve,
  Pipe,
  PipePlaceholder,
  IRCompressor,
  ValveConnect,
  Pond,
  MiddlePipeL,
  WaterTank,
  AirTank,
  Compressor,
  TriplePipeFork,
  DoublePipeFork,
  PipeFork,
  ConnectionIndicator,
  Aftercooler,
  AftercoolerFan,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowCustom,
  PumpsRow,
  PumpsRowSmall,
  PumpsRowExtraSmall,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Weather,
  Info,
  MultiInfo,
  // Totals,
  Statistic,
  MobileStatTable,
  PLCStateRouter,
  PLCColorRouter,
  LoadingIndicator,
  Alarms,
} from 'isno/lib/components/displays';

import {
  AutoValveControl,
  VFDPumpControl,
  InfoControl,
  ToggleControl,
  AlarmReset,
  AftercoolerPID,
  MultiSetpointControl,
  InfoButton,
} from 'isno/lib/components/controls';

import { startCase } from 'lodash';

import PLC from 'isno/lib/static/images/PLC.png';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  waterFlowRate: 'water_flow',
  suctionWaterPressure: 'suction_water_pressure',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  dischargeAirPressure: 'discharge_air_pressure',
  // Pump Data
  speed: 'speed',
  dischargePressure: 'discharge_water_pressure',
  dischargeTemperature: 'discharge_water_temperature',
  amps: 'current',
  hours: 'run_time',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amps',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
  // compressor IOValues
  bypassValve: 'bypass_valve',
  inletValve: 'inlet_valve',
  systemPressure: 'system_pressure',
  oilPressure: 'oil_pressure',
  oilTemperature: 'oil_temperature',
  stage1Temperature: 'stage1_temperature',
  stage1Pressure: 'stage1_pressure',
  stage1Vibration: 'stage1_vibration',
  stage2Temperature: 'stage2_temperature',
  stage2Pressure: 'stage2_pressure',
  stage2Vibration: 'stage2_vibration',
  stage3Temperature: 'stage3_temperature',
  stage3Pressure: 'stage3_pressure',
  stage3Vibration: 'stage3_vibration',
  compressorStartCommand: 'compressor_start_command',
  compressorStopCommand: 'compressor_stop_command',
  compressorModulate: 'compressor_modulate',
  compressorModulateCommand: 'compressor_modulate_command',
  compressorUnloaded: 'compressor_unloaded',
  compressorUnloadCommand: 'compressor_unload_command',
  compressorResetCommand: 'compressor_reset_command',
  systemPressureSetpoint: 'system_pressure_setpoint',
  compressorPressureSetpointCommand: 'compressor_pressure_setpoint_command',
};

function PrimaryScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(9),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) {
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(9);
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null;
    };
  }, []);

  const locationEquipment5 = props.locations?.locations?.[9]?.equipment;
  if (!props.locations?.locations?.[9]) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer height="fit-content" flex="3" border label="Primary Pumphouse">
            <InfoControlRow>
              <MultiSetpointControl
                title="CV System PID"
                pGainLabel="Back P-Gain SP"
                iGainLabel="Back I-Gain SP"
                pGainLabel2="Hill P-Gain SP"
                iGainLabel2="Hill I-Gain SP"
                settings
                pidData={locationEquipment5?.['CV System PID']}
                IOValueIDs={[
                  `${locationEquipment5?.['CV System PID']?.ioValues?.back_auto_setpoint?.id}`,
                  `${locationEquipment5?.['CV System PID']?.ioValues?.hill_auto_setpoint?.id}`,
                  `${locationEquipment5?.['CV System PID']?.ioValues?.manual_setpoint?.id}`,
                ]}
                statistics={[
                  `${locationEquipment5?.['CV System PID']?.ioValues?.back_auto_setpoint.value}`,
                  `${locationEquipment5?.['CV System PID']?.ioValues?.hill_auto_setpoint.value}`,
                  `${locationEquipment5?.['CV System PID']?.ioValues?.manual_setpoint.value}`,
                ]}
                labels={['Back Auto SP:', 'Hill Auto SP:', 'Manual Setpoint:']}
                units={[
                  locationEquipment5?.['CV System PID']?.ioValues?.back_auto_setpoint?.ioValueType?.units,
                  locationEquipment5?.['CV System PID']?.ioValues?.hill_auto_setpoint?.ioValueType?.units,
                  locationEquipment5?.['CV System PID']?.ioValues?.manual_setpoint?.ioValueType?.units,
                ]}
                setIOValue={props.setIOValue}
                IOValueKeys={{
                  autoManual: 'auto_manual',
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  pressureMode: 'hill_back',
                  pGainSetpoint: 'back_pgain_setpoint',
                  iGainSetpoint: 'back_igain_setpoint',
                  pGainSetpoint2: 'hill_pgain_setpoint',
                  iGainSetpoint2: 'hill_igain_setpoint',
                }}
                readValues={[0, 1]}
                writeValues={[0, 1]}
                buttons
              />
              {/* <MultiInfo
                title="Seal Saver"
                subtitles={['Pump Seal', 'Cooling']}
                statistics={[
                  locationEquipment5?.Other?.ioValues?.pump_seal?.value,
                  locationEquipment5?.Other?.ioValues?.pump_cooling?.value,
                ]}
                ids={[
                  locationEquipment5?.Other?.ioValues?.pump_seal?.id,
                  locationEquipment5?.Other?.ioValues?.pump_cooling?.id,
                ]}
                labels={[
                  locationEquipment5?.Other?.ioValues?.pump_seal?.value ? 'Alarm' : 'OK',
                  locationEquipment5?.Other?.ioValues?.pump_cooling?.value ? 'Alarm' : 'OK',
                ]}
                colors={[
                  locationEquipment5?.Other?.ioValues?.pump_seal?.value ? theme.alarm : theme.onGreen,
                  locationEquipment5?.Other?.ioValues?.pump_cooling?.value ? theme.alarm : theme.onGreen,
                ]}
              /> */}
              <Info
                title="Room Temp"
                statistic={locationEquipment5?.Other?.ioValues?.room_temperature?.value}
                label={locationEquipment5?.Other?.ioValues?.room_temperature?.ioValueType?.units}
                precision={1}
              />
              <Info
                title="River Level"
                statistic={locationEquipment5?.Other?.ioValues?.river_level?.value}
                label={locationEquipment5?.Other?.ioValues?.river_level?.ioValueType?.units}
                precision={1}
              />
              <Info
                title="Instrument Air"
                statistic={locationEquipment5?.IA?.ioValues?.enabled?.value ? 'Enabled' : 'Disabled'}
                color={locationEquipment5?.IA?.ioValues?.enabled?.value ? theme.onGreen : theme.alarm}
                label=""
              />
              <InfoButton
                title="Trip Vol."
                statistic={locationEquipment5?.FT?.ioValues?.trip_volume?.value}
                label={locationEquipment5?.FT?.ioValues?.trip_volume?.ioValueType?.units}
                precision={0}
                buttonWriteId={locationEquipment5?.FT?.ioValues?.trip_volume_reset?.id}
                buttonText="Reset"
                setIOValue={props.setIOValue}
              />
              <InfoButton
                title="Total Vol."
                statistic={locationEquipment5?.FT?.ioValues?.total_volume?.value}
                label={locationEquipment5?.FT?.ioValues?.total_volume?.ioValueType?.units}
                precision={0}
                buttonWriteId={locationEquipment5?.FT?.ioValues?.total_volume_reset?.id}
                buttonText="Reset"
                setIOValue={props.setIOValue}
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[16])}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[16], theme)}
              />
              <Alarms
                title="General Alarms"
                ioValues={locationEquipment5?.Other?.ioValues}
              />
              <AlarmReset
                title="System Reset"
                buttonText="Reset"
                alarmData={locationEquipment5?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarm: 'master_alarm',
                  masterAlarmReset: 'alarm_reset',
                }}
                resetWriteValue={1}
              />
            </InfoControlRow>
            <PumpsRowCustom height="24px">
              <PipePlaceholder hidden />
              <PipePlaceholder pipeAbove="right" />
              <div css={styles.flexRow}>
                <PipePlaceholder pipeAbove="middle" minWidth="0px" />
                <div css={css`margin-top: -3px`}>
                  <Statistic
                    label={locationEquipment5?.Other?.ioValues[IOValueKeys.dischargePressure]?.ioValueType?.units}
                    border
                    statistic={locationEquipment5?.Other?.ioValues[IOValueKeys.dischargePressure]?.value}
                  />
                </div>
                <div css={css`margin-top: -3px`}>
                  <Statistic
                    label={locationEquipment5?.Other?.ioValues[IOValueKeys.dischargeTemperature]?.ioValueType?.units}
                    border
                    statistic={locationEquipment5?.Other?.ioValues[IOValueKeys.dischargeTemperature]?.value}
                    precision={1}
                  />
                </div>
                <div css={css`margin-top: -3px`}>
                  <Statistic
                    label={locationEquipment5?.Other?.ioValues?.discharge_water_flow?.ioValueType?.units}
                    border
                    statistic={locationEquipment5?.Other?.ioValues?.discharge_water_flow?.value}
                  />
                </div>
              </div>
              <div css={styles.flexRow}>
                <PipePlaceholder horizontalAbove capAfter />
                <div css={[styles.mobileLabel, css`position: absolute; right: 18px;`]}>
                  To Hill
                </div>
              </div>
              <PipePlaceholder hidden />
            </PumpsRowCustom>
            <PumpsRowCustom height="214px">
              <PipePlaceholder hidden />
              <ValveConnect
                showOnMobile
                nameLeft="-51px"
                nameTop="-9px"
                percentTop="23px"
                percentLeft="-56px"
                minWidth="9%"
                percent
                valveData={{
                  ...locationEquipment5?.CV1,
                  state: locationEquipment5?.CV1?.ioValues?.position?.value,
                  id: `controlValve_${locationEquipment5?.CV1?.id}`,
                }}
                setIOValue={props.setIOValue}
                readValues={[100, 0]}
                valveStateKeys={{
                  0: '0',
                  100: '100',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  100: theme.onGreen,
                }}
                IOValueKeys={{
                  position: 'position',
                }}
                greenImageKey="Opened"
                binary={false}
              />
              <ValveConnect
                showOnMobile
                nameLeft="-51px"
                nameTop="-9px"
                percentTop="23px"
                percentLeft="-56px"
                minWidth="9%"
                percent
                valveData={{
                  ...locationEquipment5?.CV2,
                  state: locationEquipment5?.CV2?.ioValues?.position?.value,
                  id: `controlValve_${locationEquipment5?.CV2?.id}`,
                }}
                setIOValue={props.setIOValue}
                readValues={[100, 0]}
                valveStateKeys={{
                  0: '0',
                  100: '100',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  100: theme.onGreen,
                }}
                IOValueKeys={{
                  position: 'position',
                }}
                greenImageKey="Opened"
                binary={false}
              />
              <PipePlaceholder hidden />
              <PipePlaceholder hidden />
            </PumpsRowCustom>
            <PumpsRowCustom height="220px">
              <Pump
                pipeAbove="right"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment5.P1}
                otherData={locationEquipment5.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                  localRemote: 'local_remote',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment5?.P1?.ioValues?.low_amperage,
                  locationEquipment5?.P1?.ioValues?.fault,
                ]}
                stats={[
                  {
                    stat: locationEquipment5?.P1?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment5?.P1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment5?.P1?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment5?.P1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                pipeAbove="middle"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment5.P2}
                otherData={locationEquipment5.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                  localRemote: 'local_remote',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment5?.P2?.ioValues?.low_amperage,
                  locationEquipment5?.P2?.ioValues?.fault,
                ]}
                stats={[
                  {
                    stat: locationEquipment5?.P2?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment5?.P2?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment5?.P2?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment5?.P2?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                pipeAbove="middle"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment5.P3}
                otherData={locationEquipment5.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                  localRemote: 'local_remote',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment5?.P3?.ioValues?.low_amperage,
                  locationEquipment5?.P3?.ioValues?.fault,
                ]}
                stats={[
                  {
                    stat: locationEquipment5?.P3?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment5?.P3?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment5?.P3?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment5?.P3?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <div css={styles.flexRow}>
                <Pump
                  pipeAbove="middle"
                  componentMinWidth="135px"
                  setIOValue={props.setIOValue}
                  pumpData={locationEquipment5.P4}
                  otherData={locationEquipment5.Other}
                  stateKey={{
                    0: language.off,
                    1: language.on,
                    2: language.warning,
                    3: language.alarm,
                  }}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  IOValueKeys={{
                    start: 'start',
                    stop: 'stop',
                    started: 'run_feedback',
                    stopped: 'run_feedback',
                    localRemote: 'local_remote',
                  }}
                  writeValues={[1, 1]}
                  readValues={[1, 0]}
                  alarms={[
                    locationEquipment5?.P4?.ioValues?.low_amperage,
                    locationEquipment5?.P4?.ioValues?.fault,
                  ]}
                  stats={[
                    {
                      stat: locationEquipment5?.P4?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationEquipment5?.P4?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment5?.P4?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationEquipment5?.P4?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                />
                <div css={css`margin-top: -3px`}>
                  <Statistic
                    label={locationEquipment5?.Other?.ioValues?.back_water_pressure?.ioValueType?.units}
                    border
                    statistic={locationEquipment5?.Other?.ioValues?.back_water_pressure?.value}
                  />
                </div>
              </div>
              <Pump
                pipeAbove="middle"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment5.P5}
                otherData={locationEquipment5.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                  localRemote: 'local_remote',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment5?.P5?.ioValues?.low_amperage,
                  locationEquipment5?.P5?.ioValues?.fault,
                ]}
                stats={[
                  {
                    stat: locationEquipment5?.P5?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment5?.P5?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment5?.P5?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment5?.P5?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <div css={[styles.flexRow, css`flex: 2;`]}>
                <Pump
                  pipeAbove="middle"
                  setIOValue={props.setIOValue}
                  pumpData={locationEquipment5.P6}
                  otherData={locationEquipment5.Other}
                  stateKey={{
                    0: language.off,
                    1: language.on,
                    2: language.warning,
                    3: language.alarm,
                  }}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  IOValueKeys={{
                    start: 'start',
                    stop: 'stop',
                    started: 'run_feedback',
                    stopped: 'run_feedback',
                    localRemote: 'local_remote',
                  }}
                  writeValues={[1, 1]}
                  readValues={[1, 0]}
                  alarms={[
                    locationEquipment5?.P6?.ioValues?.low_amperage,
                    locationEquipment5?.P6?.ioValues?.fault,
                  ]}
                  stats={[
                    {
                      stat: locationEquipment5?.P6?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationEquipment5?.P6?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment5?.P6?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationEquipment5?.P6?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                />
                <div css={css`height: 100%;`}>
                  <ValveConnect
                    horizontal="above"
                    noPipe
                    readOnly
                    showOnMobile
                    nameLeft="-3px"
                    nameTop="-63px"
                    percentTop="23px"
                    percentLeft="2px"
                    minWidth="51px"
                    percent
                    valveData={{
                      ...locationEquipment5?.BV,
                      name: 'Bypass',
                      state: locationEquipment5?.BV?.ioValues?.closed_opened?.value,
                      id: `controlValve_${locationEquipment5?.BV?.id}`,
                    }}
                    setIOValue={props.setIOValue}
                    readValues={[1, 0]}
                    valveStateKeys={{
                      0: 'Closed',
                      1: 'Opened',
                    }}
                    valveStateColorKeys={{
                      0: theme.offRed,
                      1: theme.onGreen,
                    }}
                    IOValueKeys={{
                      opened: 'closed_opened',
                      closed: 'closed_opened',
                      position: 'position',
                    }}
                    greenImageKey="Opened"
                    redImageKey="Closed"
                  />
                </div>
                <PipePlaceholder pipeAbove="left" minWidth="0px" />
              </div>
            </PumpsRowCustom>
            <PumpsRowCustom height="82px">
              <Pond color={theme.pipeColors.water}>
                <Info
                  title="Well Level"
                  statistic={locationEquipment5?.Other?.ioValues?.well_level?.value}
                  precision={1}
                  label={locationEquipment5?.Other?.ioValues?.well_level?.ioValueType?.units}
                  alertText={locationEquipment5?.Other?.ioValues?.low_well_level?.value ? 'Low Well Level' : null}
                  alertTop="0px"
                  alertLeft="-19px"
                />
              </Pond>
            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    mobileLabel: css`
      top: 17px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        top: -22px;
      }
    `,
    flexRow: css`
      height: 100%;
      display: flex;
      flex: 1;
      flex-direction: row;
      min-width: 175px;
      position: relative;
    `,
    floatPump: css`
      position: absolute;
      top: 35px;
      left: 5px;
    `,
    flexCol: css`
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 100%;
    `,
    pumpWrapper: css`
      display: flex;
      flex: 3;
      flex-direction: row;
      height: 100%;
      position: relative;
    `,
    redBox: css`
      border: 2px dashed red;
      z-index: 3;
      background-color: rgba(0,0,0,0.5);
      position: absolute;
      top: 0;
      left: 0;
      width: 133%;
      height: 66%;
      margin: 30px 0px 0px -9px;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

PrimaryScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
};

PrimaryScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(PrimaryScreen);
