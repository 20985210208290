import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

import {
  Pump,
  Valve,
  Pipe,
  PipePlaceholder,
  ValveConnect,
  Pond,
  MiddlePipeL,
  WaterTank,
  AirTank,
  Compressor,
  TriplePipeFork,
  DoublePipeFork,
  PipeFork,
  ConnectionIndicator,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowMedium,
  PumpsRow,
  PumpsRowCustom,
  PumpsRowSmall,
  PumpsRowExtraSmall,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Weather,
  Info,
  MultiInfo,
  MultiColInfo,
  // Totals,
  Statistic,
  MobileStatTable,
  Alarms,
  PLCStateRouter,
  PLCColorRouter,
  LoadingIndicator,
} from 'isno/lib/components/displays';

import {
  AutoValveControl,
  VFDPumpControl,
  InfoControl,
  ToggleControl,
  AlarmReset,
  MultiSetpointControl,
  InfoButton,
} from 'isno/lib/components/controls';

import { startCase } from 'lodash';

import PLC from 'isno/lib/static/images/PLC.png';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  waterFlowRate: 'water_flow',
  suctionWaterPressure: 'suction_water_pressure',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  dischargeAirPressure: 'discharge_air_pressure',
  // Pump Data
  speed: 'speed',
  dischargePressure: 'discharge_water_pressure',
  dischargeTemperature: 'discharge_water_temperature',
  amps: 'current',
  hours: 'run_time',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amps',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
};

function BoosterScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(8),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) {
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(8);
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null;
    };
  }, []);

  const pumpMainStatusKey = {
    0: 'READY',
    1: 'RUN',
    2: 'Switch',
    3: 'High Temp',
    4: 'Low Level',
    5: 'Run Fail',
    6: 'Disabled',
    7: 'Comm',
    8: 'Local',
    9: 'Fault',
    10: 'Suction',
    11: 'PRIMARY',
  };

  const pumpMainStatusColorKey = {
    0: `${theme.onGreen}`,
    1: `${theme.onGreen}`,
    2: `${theme.alarm}`,
    3: `${theme.alarm}`,
    4: `${theme.alarm}`,
    5: `${theme.alarm}`,
    6: `${theme.alarm}`,
    7: `${theme.alarm}`,
    8: `${theme.alarm}`,
    9: `${theme.alarm}`,
    10: `${theme.alarm}`,
    11: `${theme.onGreen}`,
  };

  const locationEquipment8 = props.locations?.locations?.[8]?.equipment;
  if (!props.locations?.locations?.[8]) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  // TODO: fix style names... maybe use styled components
  const componentMinWidth = '70px';
  const component2MinWidth = '140px';
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer height="fit-content" flex="3" border label="Booster">
            <InfoControlRow>
              <VFDPumpControl
                title="PID Control"
                pumpData={{ id: `controlValve_${locationEquipment8?.PID?.id}` }}
                pidData={locationEquipment8?.PID}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'auto_mode',
                  manualMode: 'manual_mode',
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                }}
                readValues={[1, 1]}
                writeValues={[0, 1]}
              />
              <Info
                title="PID Fill Mode"
                statistic={locationEquipment8?.PID?.ioValues?.fill_mode_done?.value === 1 ? 'Done' : 'Filling'}
                color={locationEquipment8?.PID?.ioValues?.fill_mode_done?.value === 1 ? theme.onGreen : theme.waterColor}
              />
              <MultiInfo
                title="Pump Timers"
                subtitles={['Next Start', 'Next Stop']}
                statistics={[
                  locationEquipment8?.Other?.ioValues?.pump_starting_timer?.value,
                  locationEquipment8?.Other?.ioValues?.pump_stopping_timer?.value,
                ]}
                ids={[
                  locationEquipment8?.Other?.ioValues?.pump_starting_timer?.id,
                  locationEquipment8?.Other?.ioValues?.pump_stopping_timer?.id,
                ]}
                labels={[
                  locationEquipment8?.Other?.ioValues?.pump_starting_timer?.ioValueType?.units,
                  locationEquipment8?.Other?.ioValues?.pump_stopping_timer?.ioValueType?.units,
                ]}
              />
              <Info
                title="Room Temp."
                statistic={locationEquipment8?.Other?.ioValues?.room_temperature?.value}
                label={locationEquipment8?.Other?.ioValues?.room_temperature?.ioValueType?.units}
                precision={1}
              />
              <Info
                title="Current"
                statistic={locationEquipment8?.Other?.ioValues?.[IOValueKeys.amps]?.value}
                label={locationEquipment8?.Other?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units}
              />
              <Info
                title="Voltage"
                statistic={locationEquipment8?.Other?.ioValues?.voltage?.value}
                label={locationEquipment8?.Other?.ioValues?.voltage?.ioValueType?.units}
              />
              <MultiColInfo
                title="Flow Volumes"
                firstColSubtitles={['Today', 'Monthly', 'Yearly']}
                secondColSubtitles={['Yesterday', 'Last Month', 'Last Year']}
                thirdColSubtitles={['Total']}
                firstColStatistics={[
                  locationEquipment8?.FT?.ioValues?.daily_volume?.value,
                  locationEquipment8?.FT?.ioValues?.monthly_volume?.value,
                  locationEquipment8?.FT?.ioValues?.yearly_volume?.value,
                ]}
                secondColStatistics={[
                  locationEquipment8?.FT?.ioValues?.yesterday_volume?.value,
                  locationEquipment8?.FT?.ioValues?.last_month_volume?.value,
                  locationEquipment8?.FT?.ioValues?.last_year_volume?.value,
                ]}
                thirdColStatistics={[
                  locationEquipment8?.FT?.ioValues?.total_volume?.value,
                ]}
                firstColLabels={[
                  locationEquipment8?.FT?.ioValues?.daily_volume?.ioValueType?.units,
                  locationEquipment8?.FT?.ioValues?.monthly_volume?.ioValueType?.units,
                  locationEquipment8?.FT?.ioValues?.yearly_volume?.ioValueType?.units,
                ]}
                secondColLabels={[
                  locationEquipment8?.FT?.ioValues?.yesterday_volume?.ioValueType?.units,
                  locationEquipment8?.FT?.ioValues?.last_month_volume?.ioValueType?.units,
                  locationEquipment8?.FT?.ioValues?.last_year_volume?.ioValueType?.units,
                ]}
                thirdColLabels={[
                  locationEquipment8?.FT?.ioValues?.total_volume?.ioValueType?.units,
                ]}
                firstColIds={[
                  locationEquipment8?.FT?.ioValues?.daily_volume?.id,
                  locationEquipment8?.FT?.ioValues?.monthly_volume?.id,
                  locationEquipment8?.FT?.ioValues?.yearly_volume?.id,
                ]}
                secondColIds={[
                  locationEquipment8?.FT?.ioValues?.yesterday_volume?.id,
                  locationEquipment8?.FT?.ioValues?.last_month_volume?.id,
                  locationEquipment8?.FT?.ioValues?.last_year_volume?.id,
                ]}
                thirdColIds={[
                  locationEquipment8?.FT?.ioValues?.total_volume?.id,
                ]}
                firstColPrecisions={[0, 0, 0]}
                secondColPrecisions={[0, 0, 0]}
                thirdColPrecisions={[1]}
              />
              <InfoButton
                title="Trip Vol."
                statistic={locationEquipment8?.FT?.ioValues?.trip_volume?.value}
                label={locationEquipment8?.FT?.ioValues?.trip_volume?.ioValueType?.units}
                precision={0}
                buttonWriteId={locationEquipment8?.FT?.ioValues?.trip_volume_reset?.id}
                buttonText="Reset"
                setIOValue={props.setIOValue}
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[15])} // plcId 15: booster
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[15], theme)}
              />
              <Alarms
                title="General Alarms"
                ioValues={locationEquipment8?.Other?.ioValues}
              />
              <AlarmReset
                title="System Reset"
                buttonText="Reset"
                alarmData={locationEquipment8?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarm: 'master_alarm',
                  masterAlarmReset: 'master_alarm_reset',
                }}
                resetWriteValue={1}
              />
            </InfoControlRow>
            <PumpsRowCustom height="75px">
              <div css={css`display: flex; flex-direction: column; height: 100%; flex: 5.4; min-width: 175px;`}>
                <PipePlaceholder hidden />
              </div>
              <div css={styles.flexRow}>
                <div css={styles.flexCol}>
                  <div css={css`position: absolute; top: 0px; left: -18px; z-index: 2; width: 51px; height: 75px;`}>
                    <PipePlaceholder pipeAbove="right" minWidth="0px" />
                  </div>
                  <div css={css`margin-left: 15px; display: flex; flex: 2;`}>
                    <ValveConnect
                      capAfter
                      minWidth="0px"
                      horizontal="above"
                      manual
                      readOnly
                      valveStateKeys={{
                        0: 'Open',
                        1: 'Closed',
                      }}
                      valveStateColorKeys={{
                        0: theme.onGreen,
                        1: theme.offRed,
                      }}
                      valveData={{ state: locationEquipment8?.Other?.ioValues?.[IOValueKeys.ib1]?.value }}
                    />
                  </div>
                  <div css={[styles.hiddenMobile, css`position: absolute; top: 16px; right: 15px;`]}>
                    Kings Spurline
                  </div>
                  <div css={[styles.hiddenMobile, css`position: absolute; bottom: -34px; right: 15px;`]}>
                    Upper Tote
                  </div>
                </div>
              </div>
            </PumpsRowCustom>
            <PumpsRowSmall>
              <div css={css`display: flex; flex-direction: column; height: 100%; flex: 2; min-width: 175px; position: relative;`}>
                <ValveConnect
                  capBefore
                  minWidth="0px"
                  horizontal="above"
                  manual
                  readOnly
                  valveStateKeys={{
                    0: 'Open',
                    1: 'Closed',
                  }}
                  valveStateColorKeys={{
                    0: theme.onGreen,
                    1: theme.offRed,
                  }}
                  valveData={{ state: locationEquipment8?.Other?.ioValues?.[IOValueKeys.ib1]?.value }}
                />
                <div css={[styles.hiddenMobile, css`position: absolute; top: 17px; left: 15px;`]}>
                  Lower Timberline
                </div>
              </div>
              <div css={[styles.pumpWrapper, styles.hiddenMobile]}>
                <div css={[styles.redBox, css`margin: 20px 0px 0px 3px;`]} />
                <Pump
                  pipeAbove="middle"
                  percent
                  setIOValue={props.setIOValue}
                  componentMinWidth={componentMinWidth}
                  pumpData={locationEquipment8?.P6}
                  otherData={locationEquipment8?.Other}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  IOValueKeys={{
                    start: 'disable_enable',
                    stop: 'disable_enable',
                    started: 'enabled',
                    stopped: 'enabled',
                    speed: 'speed',
                  }}
                  writeValues={[1, 0]}
                  readValues={[1, 0]}
                  alarms={[
                    locationEquipment8?.P6?.ioValues?.modbus_communication_error,
                    locationEquipment8?.P6?.ioValues?.fail_to_run,
                    locationEquipment8?.P6?.ioValues?.low_suction_pressure,
                    locationEquipment8?.P6?.ioValues?.low_suction_pressure_fault,
                    locationEquipment8?.P6?.ioValues?.high_seal_temperature,
                    locationEquipment8?.P6?.ioValues?.high_motor_temperature,
                  ]}
                  statuses={[
                    {
                      id: locationEquipment8?.P6?.ioValues?.status?.id,
                      status: locationEquipment8?.P6?.ioValues?.status?.value,
                      statusKey: pumpMainStatusKey,
                      statusColorKey: pumpMainStatusColorKey,
                    },
                    {
                      id: locationEquipment8?.P6?.ioValues?.auto_enabled?.id,
                      status: locationEquipment8?.P6?.ioValues?.auto_enabled?.value,
                      statusKey: {
                        0: '',
                        1: language.auto,
                      },
                      statusColorKey: {
                        0: '',
                        1: theme.auto,
                      },
                    },
                  ]}
                  stats={[
                    {
                      stat: locationEquipment8?.P6?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationEquipment8?.P6?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment8?.P6?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationEquipment8?.P6?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                />
                <PipePlaceholder minWidth={componentMinWidth} pipeAbove="middle" />
                <Pump
                  pipeAbove="middle"
                  percent
                  setIOValue={props.setIOValue}
                  componentMinWidth={componentMinWidth}
                  pumpData={locationEquipment8?.P4}
                  otherData={locationEquipment8?.Other}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  IOValueKeys={{
                    start: 'disable_enable',
                    stop: 'disable_enable',
                    started: 'enabled',
                    stopped: 'enabled',
                    speed: 'speed',
                  }}
                  writeValues={[1, 0]}
                  readValues={[1, 0]}
                  alarms={[
                    locationEquipment8?.P4?.ioValues?.modbus_communication_error,
                    locationEquipment8?.P4?.ioValues?.fail_to_run,
                    locationEquipment8?.P4?.ioValues?.low_suction_pressure,
                    locationEquipment8?.P4?.ioValues?.low_suction_pressure_fault,
                    locationEquipment8?.P4?.ioValues?.high_seal_temperature,
                    locationEquipment8?.P4?.ioValues?.high_motor_temperature,
                  ]}
                  statuses={[
                    {
                      id: locationEquipment8?.P4?.ioValues?.status?.id,
                      status: locationEquipment8?.P4?.ioValues?.status?.value,
                      statusKey: pumpMainStatusKey,
                      statusColorKey: pumpMainStatusColorKey,
                    },
                    {
                      id: locationEquipment8?.P4?.ioValues?.auto_enabled?.id,
                      status: locationEquipment8?.P4?.ioValues?.auto_enabled?.value,
                      statusKey: {
                        0: '',
                        1: language.auto,
                      },
                      statusColorKey: {
                        0: '',
                        1: theme.auto,
                      },
                    },
                  ]}
                  stats={[
                    {
                      stat: locationEquipment8?.P4?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationEquipment8?.P4?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment8?.P4?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationEquipment8?.P4?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                />
              </div>
              <PipePlaceholder minWidth={componentMinWidth} pipeAbove="middle" />
              <Pump
                pipeAbove="middle"
                percent
                setIOValue={props.setIOValue}
                componentMinWidth={componentMinWidth}
                pumpData={locationEquipment8?.P2}
                otherData={locationEquipment8?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'disable_enable',
                  stop: 'disable_enable',
                  started: 'enabled',
                  stopped: 'enabled',
                  speed: 'speed',
                }}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment8?.P2?.ioValues?.modbus_communication_error,
                  locationEquipment8?.P2?.ioValues?.fail_to_run,
                  locationEquipment8?.P2?.ioValues?.low_suction_pressure,
                  locationEquipment8?.P2?.ioValues?.low_suction_pressure_fault,
                  locationEquipment8?.P2?.ioValues?.high_seal_temperature,
                  locationEquipment8?.P2?.ioValues?.high_motor_temperature,
                ]}
                statuses={[
                  {
                    id: locationEquipment8?.P2?.ioValues?.status?.id,
                    status: locationEquipment8?.P2?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                  {
                    id: locationEquipment8?.P2?.ioValues?.auto_enabled?.id,
                    status: locationEquipment8?.P2?.ioValues?.auto_enabled?.value,
                    statusKey: {
                      0: '',
                      1: language.auto,
                    },
                    statusColorKey: {
                      0: '',
                      1: theme.auto,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment8?.P2?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment8?.P2?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment8?.P2?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment8?.P2?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <div css={[styles.flexRow, css`min-width: ${component2MinWidth}; flex: 2;`]}>
                <div css={css`width: 0px;`}>
                  <PipePlaceholder />
                </div>
                <div css={[styles.mobilePadding, styles.flexRow, css`min-width: ${component2MinWidth}; height: 0px;`]}>
                  <PipePlaceholder minWidth="40px" horizontalAbove />
                  <div css={css`margin-top: -3px; height: 100%;`}>
                    <Statistic
                      label={locationEquipment8?.Other?.ioValues?.discharge_water_pressure?.ioValueType?.units}
                      border
                      statistic={locationEquipment8?.Other?.ioValues?.discharge_water_pressure?.value}
                    />
                  </div>
                  <div css={css`margin-top: -3px; height: 100%;`}>
                    <Statistic
                      statistic={locationEquipment8?.Other?.ioValues?.discharge_water_flow?.value}
                      label={locationEquipment8?.Other?.ioValues?.discharge_water_flow?.ioValueType?.units}
                      border
                    />
                  </div>
                  <div css={css`position: relative; margin-top: -3px; height: 100%;`}>
                    <Statistic
                      label={locationEquipment8?.Other?.ioValues?.water_temperature?.ioValueType?.units}
                      border
                      statistic={locationEquipment8?.Other?.ioValues?.water_temperature?.value}
                      precision={1}
                    />
                    <div css={[styles.showMobile, css`position: absolute; top: 25px; right: 26px;`]}>
                      Discharge
                    </div>
                  </div>
                  <PipePlaceholder minWidth="0px" horizontalAbove />
                </div>
              </div>
              {/* <div css={css`display: flex; flex-direction: column; height: 100%; flex: 2; min-width: 175px;`}>
                <div css={css`width: 0px; height: 100%;`}>
                  <PipePlaceholder />
                </div>
                <div css={styles.flexCol}>
                  <PipePlaceholder horizontalBelow capAfter />
                  <div css={css`position: absolute; top: -23px; right: 18px;`}>
                    20&quot; Supply
                  </div>
                </div>
              </div> */}

              <div css={[styles.flexRow, css`flex: 1.666;`]}>
                <div css={styles.flexCol}>
                  <div css={css`position: absolute; top: 0px; left: 0px; z-index: 2; width: 15px; height: 100%;`}>
                    <PipePlaceholder horizontalAbove minWidth="0px" />
                  </div>
                  <div css={css`margin-left: 15px; display: flex; flex: 1;`}>
                    <ValveConnect
                      capAfter
                      minWidth="0px"
                      horizontal="above"
                      manual
                      readOnly
                      valveStateKeys={{
                        0: 'Open',
                        1: 'Closed',
                      }}
                      valveStateColorKeys={{
                        0: theme.onGreen,
                        1: theme.offRed,
                      }}
                      valveData={{ state: locationEquipment8?.Other?.ioValues?.[IOValueKeys.ib1]?.value }}
                    />
                  </div>
                </div>
              </div>
            </PumpsRowSmall>
            <PumpsRowSmall>
              <div css={css`display: flex; flex-direction: column; height: 100%; flex: 2; min-width: 175px;`}>
                <PipePlaceholder hidden />
              </div>
              <PipePlaceholder minWidth={componentMinWidth} pipeBelow="right" />

              <div css={[styles.pumpWrapper, styles.hiddenMobile, css`flex: 2;`]}>
                <div css={[styles.redBox, css`margin: 9px 0px 0px 2px; width: 100%;`]} />
                <Pump
                  pipeBelow="middle"
                  percent
                  setIOValue={props.setIOValue}
                  componentMinWidth={componentMinWidth}
                  pumpData={locationEquipment8?.P5}
                  otherData={locationEquipment8?.Other}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  IOValueKeys={{
                    start: 'disable_enable',
                    stop: 'disable_enable',
                    started: 'enabled',
                    stopped: 'enabled',
                    speed: 'speed',
                  }}
                  writeValues={[1, 0]}
                  readValues={[1, 0]}
                  alarms={[
                    locationEquipment8?.P5?.ioValues?.modbus_communication_error,
                    locationEquipment8?.P5?.ioValues?.fail_to_run,
                    locationEquipment8?.P5?.ioValues?.low_suction_pressure,
                    locationEquipment8?.P5?.ioValues?.low_suction_pressure_fault,
                    locationEquipment8?.P5?.ioValues?.high_seal_temperature,
                    locationEquipment8?.P5?.ioValues?.high_motor_temperature,
                  ]}
                  statuses={[
                    {
                      id: locationEquipment8?.P5?.ioValues?.status?.id,
                      status: locationEquipment8?.P5?.ioValues?.status?.value,
                      statusKey: pumpMainStatusKey,
                      statusColorKey: pumpMainStatusColorKey,
                    },
                    {
                      id: locationEquipment8?.P5?.ioValues?.auto_enabled?.id,
                      status: locationEquipment8?.P5?.ioValues?.auto_enabled?.value,
                      statusKey: {
                        0: '',
                        1: language.auto,
                      },
                      statusColorKey: {
                        0: '',
                        1: theme.auto,
                      },
                    },
                  ]}
                  stats={[
                    {
                      stat: locationEquipment8?.P5?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationEquipment8?.P5?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment8?.P5?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationEquipment8?.P5?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                />
                <PipePlaceholder minWidth={componentMinWidth} pipeBelow="middle" />
              </div>
              <Pump
                pipeBelow="middle"
                percent
                setIOValue={props.setIOValue}
                componentMinWidth={componentMinWidth}
                pumpData={locationEquipment8?.P3}
                otherData={locationEquipment8?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'disable_enable',
                  stop: 'disable_enable',
                  started: 'enabled',
                  stopped: 'enabled',
                  speed: 'speed',
                }}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment8?.P3?.ioValues?.modbus_communication_error,
                  locationEquipment8?.P3?.ioValues?.fail_to_run,
                  locationEquipment8?.P3?.ioValues?.low_suction_pressure,
                  locationEquipment8?.P3?.ioValues?.low_suction_pressure_fault,
                  locationEquipment8?.P3?.ioValues?.high_seal_temperature,
                  locationEquipment8?.P3?.ioValues?.high_motor_temperature,
                ]}
                statuses={[
                  {
                    id: locationEquipment8?.P3?.ioValues?.status?.id,
                    status: locationEquipment8?.P3?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                  {
                    id: locationEquipment8?.P3?.ioValues?.auto_enabled?.id,
                    status: locationEquipment8?.P3?.ioValues?.auto_enabled?.value,
                    statusKey: {
                      0: '',
                      1: language.auto,
                    },
                    statusColorKey: {
                      0: '',
                      1: theme.auto,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment8?.P3?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment8?.P3?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment8?.P3?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment8?.P3?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <PipePlaceholder minWidth={componentMinWidth} pipeBelow="middle" />
              <Pump
                pipeBelow="middle"
                percent
                setIOValue={props.setIOValue}
                componentMinWidth={componentMinWidth}
                pumpData={locationEquipment8?.P1}
                otherData={locationEquipment8?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'disable_enable',
                  stop: 'disable_enable',
                  started: 'enabled',
                  stopped: 'enabled',
                  speed: 'speed',
                }}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment8?.P1?.ioValues?.modbus_communication_error,
                  locationEquipment8?.P1?.ioValues?.fail_to_run,
                  locationEquipment8?.P1?.ioValues?.low_suction_pressure,
                  locationEquipment8?.P1?.ioValues?.low_suction_pressure_fault,
                  locationEquipment8?.P1?.ioValues?.high_seal_temperature,
                  locationEquipment8?.P1?.ioValues?.high_motor_temperature,
                ]}
                statuses={[
                  {
                    id: locationEquipment8?.P1?.ioValues?.status?.id,
                    status: locationEquipment8?.P1?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                  {
                    id: locationEquipment8?.P1?.ioValues?.auto_enabled?.id,
                    status: locationEquipment8?.P1?.ioValues?.auto_enabled?.value,
                    statusKey: {
                      0: '',
                      1: language.auto,
                    },
                    statusColorKey: {
                      0: '',
                      1: theme.auto,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment8?.P1?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment8?.P1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment8?.P1?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment8?.P1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <div css={css`position: relative; min-width: ${componentMinWidth}; display: flex; flex-direction: row; flex: 1; height: 100%;`}>
                <PipePlaceholder minWidth="0px" horizontalBelow />
                <div css={css`margin-top: 4px; display: flex; height: 100%; justify-content: flex-end; flex-direction: column;`}>
                  <Statistic
                    label={locationEquipment8?.Other?.ioValues?.suction_water_pressure?.ioValueType?.units}
                    border
                    statistic={locationEquipment8?.Other?.ioValues?.suction_water_pressure?.value}
                  />
                  <div css={[styles.showMobile, css`position: absolute; top: 22px; right: 16px;`]}>
                    Suction
                  </div>
                </div>
              </div>
              {/* <div css={css`display: flex; flex-direction: column; height: 100%; flex: 2; min-width: 175px;`}>
                <div css={css`width: 0px; height: 100%;`}>
                  <PipePlaceholder />
                </div>
                <div css={styles.flexCol}>
                  <PipePlaceholder horizontalBelow capAfter />
                  <div css={css`position: absolute; top: -23px; right: 18px;`}>
                    20&quot; Supply
                  </div>
                </div>
              </div> */}

              <div css={[styles.flexRow, css`flex: 1.666;`]}>
                <div css={styles.flexCol}>
                  <div css={css`position: absolute; top: 32px; left: -18px; z-index: 2; width: 51px; height: 103px;`}>
                    <PipePlaceholder pipeAbove="right" minWidth="0px" />
                  </div>
                  <div css={css`position: relative; margin: -6px 0px 0px 15px; display: flex; flex: 1;`}>
                    <ValveConnect
                      capAfter
                      minWidth="0px"
                      horizontal="below"
                      manual
                      readOnly
                      valveStateKeys={{
                        0: 'Open',
                        1: 'Closed',
                      }}
                      valveStateColorKeys={{
                        0: theme.onGreen,
                        1: theme.offRed,
                      }}
                      valveData={{ state: locationEquipment8?.Other?.ioValues?.[IOValueKeys.ib1]?.value }}
                    />
                    <div css={[styles.hiddenMobile, css`position: absolute; top: 52px; right: 16px;`]}>
                      Scoot
                    </div>
                  </div>
                  <div css={css`position: relative; margin: -3px 0px 0px 15px; display: flex; flex: 1;`}>
                    <ValveConnect
                      capAfter
                      minWidth="0px"
                      horizontal="below"
                      manual
                      readOnly
                      valveStateKeys={{
                        0: 'Open',
                        1: 'Closed',
                      }}
                      valveStateColorKeys={{
                        0: theme.onGreen,
                        1: theme.offRed,
                      }}
                      valveData={{ state: locationEquipment8?.Other?.ioValues?.[IOValueKeys.ib1]?.value }}
                    />
                    <div css={[styles.hiddenMobile, css`position: absolute; top: 52px; right: 16px;`]}>
                      Chicken Pitch
                    </div>
                  </div>
                  <div css={css`position: relative; margin-left: 15px; display: flex; flex: 1;`}>
                    <PipePlaceholder horizontalBelow minWidth="0px" capAfter />
                    <div css={[styles.hiddenMobile, css`position: absolute; top: 52px; right: 16px;`]}>
                      20&#34; Supply
                    </div>
                  </div>
                </div>
              </div>
            </PumpsRowSmall>
            <PumpsRowCustom height="100px">
              <div css={css`display: flex; flex-direction: column; height: 100%; flex: 5.4; min-width: 175px;`}>
                <PipePlaceholder hidden />
              </div>
              <div css={styles.flexRow}>
                <div css={styles.flexCol}>
                  <div css={css`position: absolute; top: -15px; left: -18px; z-index: 2; width: 51px; height: 66.5px;`}>
                    <PipePlaceholder pipeBelow="right" minWidth="0px" />
                  </div>
                  <div css={css`position: relative; margin: 3px 0px 0px 15px; display: flex; flex: 1;`}>
                    <ValveConnect
                      capAfter
                      minWidth="0px"
                      horizontal="below"
                      manual
                      readOnly
                      valveStateKeys={{
                        0: 'Open',
                        1: 'Closed',
                      }}
                      valveStateColorKeys={{
                        0: theme.onGreen,
                        1: theme.offRed,
                      }}
                      valveData={{ state: locationEquipment8?.Other?.ioValues?.[IOValueKeys.ib1]?.value }}
                    />
                    <div css={[styles.hiddenMobile, css`z-index: 3; width: 160px; position: absolute; top: 52px; right: 1px;`]}>
                      Future West Mt Supply
                    </div>
                  </div>
                  <PipePlaceholder hidden />
                </div>
              </div>
            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    showMobile: css`
      display: none;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: inherit;
      }
    `,
    mobilePadding: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-bottom: 20px;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
      flex-direction: row;
      height: 100%;
      min-width: 175px;
    `,
    flexCol: css`
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 100%;
      position: relative;
    `,
    pumpWrapper: css`
      display: flex;
      flex: 3;
      flex-direction: row;
      height: 100%;
      position: relative;
    `,
    redBox: css`
      border: 2px dashed red;
      z-index: 3;
      background-color: rgba(0,0,0,0.5);
      position: absolute;
      top: 0;
      left: 0;
      width: 133%;
      height: 90%;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
    drainLable: css`
    width: 41px;
    position: absolute;
    top: 43%;
    left: 5px;
    background: #666666;
    border-radius: 2px;
    padding: 2px;
    `,
  };
};

BoosterScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
};

BoosterScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(BoosterScreen);
