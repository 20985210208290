import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

import {
  Pump,
  Valve,
  Pipe,
  PipePlaceholder,
  ValveConnect,
  Pond,
  MiddlePipeL,
  WaterTank,
  AirTank,
  Compressor,
  TriplePipeFork,
  DoublePipeFork,
  PipeFork,
  ConnectionIndicator,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowMedium,
  PumpsRow,
  PumpsRowSmall,
  PumpsRowExtraSmall,
  LabeledContainer,
  PumpsRowCustom,
} from 'isno/lib/components/layout';

import {
  Weather,
  Info,
  MultiInfo,
  // Totals,
  Statistic,
  MobileStatTable,
  PLCStateRouter,
  PLCColorRouter,
  LoadingIndicator,
} from 'isno/lib/components/displays';

import {
  AutoValveControl,
  VFDPumpControl,
  InfoControl,
  ToggleControl,
  AlarmReset,
  MultiSetpointControl,
  SequenceControl,
} from 'isno/lib/components/controls';

import { startCase } from 'lodash';

import PLC from 'isno/lib/static/images/PLC.png';
import selectorToggle from 'isno/lib/static/images/selectorToggle.svg';
import selectorToggleRotate from 'isno/lib/static/images/selectorToggleRotate.svg';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  waterFlowRate: 'water_flow',
  suctionWaterPressure: 'suction_water_pressure',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  dischargeAirPressure: 'discharge_air_pressure',
  // Pump Data
  speed: 'speed',
  dischargePressure: 'discharge_water_pressure',
  dischargeTemperature: 'discharge_water_temperature',
  amps: 'current',
  hours: 'run_time',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amps',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
};

function SecondaryScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(3),
        props.fetchLocation(2),
        props.fetchLocation(1),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) {
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(3);
    props.fetchLocation(2);
    props.fetchLocation(1);
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null;
    };
  }, []);

  const locationEquipment3 = props.locations?.locations?.[3]?.equipment;
  const locationEquipment2 = props.locations?.locations?.[2]?.equipment;
  const locationEquipment1 = props.locations?.locations?.[1]?.equipment;
  if (!props.locations?.locations?.[3] || !props.locations?.locations?.[2] || !props.locations?.locations?.[1]) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content; display: flex; flex-direction: row; flex-wrap: nowrap;">
          <BasicRow styles="min-width: fit-content; min-height: fit-content; display: flex; flex-direction: column; flex-wrap: nowrap; flex: 1;">
            <LabeledContainer styles2="display: flex; justify-content: space-between; flex-direction: column; height: 50%;" height="fit-content" border label="Tote Booster">
              <InfoControlRow>
                <Info
                  img={PLC}
                  title="PLC Status"
                  statistic={PLCStateRouter(props.plcs?.plcs?.[7])} // plcId 7: booster
                  label=""
                  color={PLCColorRouter(props.plcs?.plcs?.[7], theme)}
                />
              </InfoControlRow>
              <PumpsRowCustom height="200px">
                <PipePlaceholder horizontalBelow capBefore />
                <div css={styles.toteStat}>
                  <Statistic
                    label={locationEquipment1?.Other?.ioValues?.discharge_water_pressure?.ioValueType?.units}
                    border
                    statistic={locationEquipment1?.Other?.ioValues?.discharge_water_pressure?.value}
                  />
                  <div css={css`position: absolute; top: 109px; left: -154px; @media only screen and (max-width: ${theme.mobileBreakpoint}px) {top: 1px; left: -77px;}`}>
                    Discharge
                  </div>
                </div>
                <Pump
                  pipeBelow="middle"
                  setIOValue={props.setIOValue}
                  pumpData={locationEquipment1.P9}
                  viewOnly
                  otherData={locationEquipment1.Other}
                  stateKey={{
                    0: language.off,
                    1: language.on,
                    2: language.warning,
                    3: language.alarm,
                  }}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  IOValueKeys={{
                  // start: 'start',
                  // stop: 'stop',
                  // started: 'run_feedback',
                  // stopped: 'run_feedback',
                  // localRemote: 'local_remote',
                  }}
                  // writeValues={[1, 1]}
                  // readValues={[1, 0]}
                  alarms={[]}
                  stats={[
                    {
                      stat: locationEquipment1?.P9?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationEquipment1?.P9?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment1?.P9?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationEquipment1?.P9?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                />
                <div css={styles.flexCol}>
                  <PipePlaceholder />
                  <div css={css`display: flex; justify-content: center; width: 50px;`}>
                    <Statistic
                      label={locationEquipment1?.Other?.ioValues?.suction_water_pressure?.ioValueType?.units}
                      border
                      statistic={locationEquipment1?.Other?.ioValues?.suction_water_pressure?.value}
                    />
                  </div>
                  <PipePlaceholder pipeBelow="left" />
                </div>
              </PumpsRowCustom>
            </LabeledContainer>
            <LabeledContainer styles2="display: flex; justify-content: space-between; flex-direction: column; height: 50%;" height="fit-content" border label="Bucksaw">
              <InfoControlRow>
                <Info
                  img={PLC}
                  title="PLC Status"
                  statistic={PLCStateRouter(props.plcs?.plcs?.[6])} // plcId 6: Bucksaw
                  label=""
                  color={PLCColorRouter(props.plcs?.plcs?.[6], theme)}
                />
              </InfoControlRow>
              <PumpsRowCustom height="200px">
                <PipePlaceholder horizontalBelow capBefore />
                <div css={styles.bucksawStat}>
                  <Statistic
                    label={locationEquipment2?.Other?.ioValues?.discharge_water_flow?.ioValueType?.units}
                    border
                    statistic={locationEquipment2?.Other?.ioValues?.discharge_water_flow?.value}
                  />
                  <div css={css`position: absolute; top: 76px; left: -235px; @media only screen and (max-width: ${theme.mobileBreakpoint}px) {top: 110px; left: -10px;}`}>
                    Discharge
                  </div>
                </div>
                <PipePlaceholder horizontalBelow />
              </PumpsRowCustom>
            </LabeledContainer>
          </BasicRow>
          <BasicRow styles="min-width: fit-content; min-height: fit-content; display: flex; flex-direction: row; flex-wrap: nowrap; flex: 1;">
            <LabeledContainer styles2="display: flex; justify-content: space-between; flex-direction: column;" height="fit-content" flex="1" border label="Secondary">
              <InfoControlRow>
                <VFDPumpControl
                  title="PID Control"
                  pumpData={{ id: `controlValve_${locationEquipment3?.PID?.id}` }}
                  pidData={locationEquipment3?.PID}
                  setIOValue={props.setIOValue}
                  max="1500"
                  IOValueKeys={{
                    autoMode: 'auto_manual',
                    manualMode: 'auto_manual',
                    autoManual: 'auto_manual',
                    autoSetpoint: 'auto_setpoint',
                    manualSetpoint: 'manual_setpoint',
                  }}
                  readValues={[0, 1]}
                  writeValues={[0, 1]}
                />
                <AlarmReset
                  title="System Reset"
                  buttonText="Reset"
                  alarmData={locationEquipment3?.Other}
                  setIOValue={props.setIOValue}
                  alarmKeys={{
                    masterAlarm: 'master_alarm',
                    masterAlarmReset: 'master_alarm_reset',
                  }}
                  resetWriteValue={1}
                />
                <Info
                  img={PLC}
                  title="PLC Status"
                  statistic={PLCStateRouter(props.plcs?.plcs?.[5])} // plcId 5: secondary
                  label=""
                  color={PLCColorRouter(props.plcs?.plcs?.[5], theme)}
                />
              </InfoControlRow>
              <PumpsRowCustom height="200px">
                <div css={styles.flexRow}>
                  <div css={[styles.flexCol, css`justify-content: space-between;margin-right: -18px;`]}>
                    <div css={css`display: flex; flex-direction: row;`}>
                      <PipePlaceholder horizontalAbove minWidth="0px" capBefore />
                      <div css={css`position: relative; margin-top: -4px; height: 100%; justify-content: flex-end; display: flex; flex-direction: column;`}>
                        <Statistic
                          label={locationEquipment3?.Other?.ioValues?.candyside_water_flow?.ioValueType?.units}
                          border
                          statistic={locationEquipment3?.Other?.ioValues?.candyside_water_flow?.value}
                        />
                        <div css={styles.statLabel}>
                          Candyside
                        </div>
                      </div>
                      <PipePlaceholder horizontalAbove minWidth="0px" />
                    </div>
                    <div css={css`display: flex; flex-direction: row;`}>
                      <PipePlaceholder horizontalCenter minWidth="0px" capBefore />
                      <div css={css`position: relative; margin-top: -1px; height: 100%; justify-content: flex-end; display: flex; flex-direction: column;`}>
                        <Statistic
                          label={locationEquipment3?.Other?.ioValues?.whiffletree_water_flow?.ioValueType?.units}
                          border
                          statistic={locationEquipment3?.Other?.ioValues?.whiffletree_water_flow?.value}
                        />
                        <div css={styles.statLabel}>
                          Whiffletree
                        </div>
                      </div>
                      <PipePlaceholder horizontalCenter minWidth="0px" />
                    </div>
                    <div css={css`display: flex; flex-direction: row;`}>
                      <PipePlaceholder horizontalBelow minWidth="0px" capBefore />
                      <div css={css`position: relative; margin-top: 3px; height: 100%; justify-content: flex-end; display: flex; flex-direction: column;`}>
                        <Statistic
                          label={locationEquipment3?.Other?.ioValues?.discharge_water_flow?.ioValueType?.units}
                          border
                          statistic={locationEquipment3?.Other?.ioValues?.discharge_water_flow?.value}
                        />
                        <div css={styles.statLabel}>
                          Discharge
                        </div>
                      </div>
                      <PipePlaceholder horizontalBelow minWidth="0px" />
                    </div>
                  </div>
                  <PipePlaceholder pipeAbove="left" pipeBelow="middle" minWidth="78px" />
                </div>
                {/* <PipePlaceholder horizontalBelow capBefore /> */}
                <div css={css`margin-top: 6px; height: 100%; justify-content: flex-end; display: flex; flex-direction: column;`}>
                  <Statistic
                    label={locationEquipment3?.Other?.ioValues?.discharge_water_pressure?.ioValueType?.units}
                    border
                    statistic={locationEquipment3?.Other?.ioValues?.discharge_water_pressure?.value}
                  />
                </div>
                <div css={css`margin-top: 67px;`}>
                  <Pump
                    pipeBelow="middle"
                    setIOValue={props.setIOValue}
                    pumpData={locationEquipment3.P7}
                    otherData={locationEquipment3.Other}
                    stateKey={{
                      0: language.off,
                      1: language.on,
                      2: language.warning,
                      3: language.alarm,
                    }}
                    stateColorKey={{
                      0: `${theme.secondary}`,
                      1: `${theme.onGreen}`,
                      2: `${theme.warning}`,
                      3: `${theme.alarm}`,
                    }}
                    IOValueKeys={{
                      start: 'start',
                      stop: 'stop',
                      started: 'run_feedback',
                      stopped: 'run_feedback',
                      speed: 'speed',
                    }}
                    writeValues={[1, 1]}
                    readValues={[1, 0]}
                    alarms={[
                      locationEquipment3?.P7?.ioValues?.low_amps,
                      locationEquipment3?.P7?.ioValues?.high_seal_temperature,
                      locationEquipment3?.P7?.ioValues?.fault,
                    ]}
                    stats={[
                      {
                        stat: locationEquipment3?.P7?.ioValues?.[IOValueKeys.amps]?.value,
                        label: locationEquipment3?.P7?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                      },
                      {
                        stat: locationEquipment3?.P7?.ioValues?.[IOValueKeys.hours]?.value,
                        label: locationEquipment3?.P7?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                      },
                      {
                        stat: locationEquipment3?.P7?.ioValues?.seal_temperature?.value,
                        label: locationEquipment3?.P7?.ioValues?.seal_temperature?.ioValueType?.units,
                        precision: 1,
                      },
                    ]}
                  />
                </div>
                <div css={styles.flexCol}>
                  <PipePlaceholder />
                  <PipePlaceholder pipeBelow="left" />
                </div>
              </PumpsRowCustom>
            </LabeledContainer>
          </BasicRow>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
      flex-direction: row;
      height: 100%;
      min-width: 175px;
      position: relative;
    `,
    flexCol: css`
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 100%;
    `,
    pumpWrapper: css`
      display: flex;
      flex: 3;
      flex-direction: row;
      height: 100%;
      position: relative;
    `,
    redBox: css`
      border: 2px dashed red;
      z-index: 3;
      background-color: rgba(0,0,0,0.5);
      position: absolute;
      top: 0;
      left: 0;
      width: 133%;
      height: 66%;
      margin: 30px 0px 0px -9px;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    toteStat: css`
      position: relative;
      margin-top: 6px;
      height: 100%;
      justify-content: flex-end; 
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        margin-top: 63px;
      }
    `,
    bucksawStat: css`
      position: relative;
      margin-top: 6px;
      height: 100%;
      justify-content: flex-end;
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        justify-content: space-around;
      }
    `,
    statLabel: css`
      position: absolute;
      top: 23px;
      left: 31px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {        
        top: 1px;
        left: 55px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

SecondaryScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
};

SecondaryScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(SecondaryScreen);
